@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap);
@font-face {
  font-family: 'BestermindRegular';
  src: url(/static/media/BestermindRegular.e58a1922.ttf); 
  src: url(/static/media/BestermindRegular.e58a1922.ttf) format('embedded-opentype'), 
       url(/static/media/BestermindRegular.e58a1922.ttf)  format('truetype'), 
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
        -ms-user-select: none; 
            user-select: none; 

}


@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.navbar {
    height: 6.5rem;
    position: absolute;
    background-color: transparent;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: 'BestermindRegular';
    color: var(--primary-2);
    font-size: 2.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
}


@media (max-width:1100px) {
    .navbar--container{
        padding: 0 2rem;
    }
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}
.landing {
  min-height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #414c50;
  border-bottom: 20px solid rgb(47, 69, 98);
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  background: #414c50;
}

hr.rounded {
  display: flex;
  width: 100%;
  border-top: 10px solid #1366aa;
  /* border-radius: 5px; */
}

.landing--img {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.landing--container-left {
  flex: 35% 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  justify-content: left;
  margin: 0 1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.landing--social:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.landing--container-right {
  flex: 65% 1;
  height: 100%;
  background-color: #1366aa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lcr--content {
  /* min-width: 500px; */
  width: 55%;
  justify-content: center;
  margin-left: 50px;
  /* margin-right: 7rem; */
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  /* margin-top: 2rem; */
  margin-bottom: 1rem;
  padding-top: 4rem;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1600px) {
  .lcr--content {
    justify-content: right;
    align-items: right;
  }

}

@media (max-width: 1100px) {
  .landing--img {
    --img-size: 350px;
  }
  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    /* align-items: center; */
    margin: auto;
  }
}

@media (max-width: 900px) { 

  .landing {
    min-height: 850px;
  }
  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    /* width: 60%; */
    padding-top: 80px;
    margin-bottom: 30px;
    /* margin-top: 10%; */
    /* margin-right: 6%; */
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    /* align-items: flex-start; */
  }
  /* .lcr-buttonContainer button:nth-child(2) {
    display: none;
  } */
}

@media (max-width: 800px) {

  .landing {
    height: 700px;
  }
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
    /* height: auto; */
    /* margin-bottom:  80px; */
    /* margin-top: 180px; */
  }
  .landing--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #1fc1e9;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    margin-bottom: 60px;
  }
  .landing--container-left {
    /* flex: initial; */
    width: 100%;
    height: 35%;
    /* margin-bottom: 20%; */
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    /* padding: 0 2rem; */
    /* flex: initial; */
    height: 65%;
    width: 100%;
    background-color: #3de4e4;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    padding-bottom: 60px;
  }
  .lcr--content {
    margin-top: 20%;
    padding-top: 20px;
    /* margin-right: initial; */
    margin-left: 0;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    /* width: 90%; */
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .lcr-buttonContainer button {
    margin-top: 10px;
  } 
}

@media (max-width: 500px) {
  /* .lcr--content {
    margin-top: 35%;
  } */
}
@media (max-width: 370px) {
  .lcr--content {
    margin-top: 60%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    height: 100%;
  }

  .landing--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* min-height: 60vh; */
    padding: 2.5rem 2rem 2rem 2rem;
}

.skillsHeader {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
}

.skill--scroll {
    width: 100%;
    margin: 0 2rem;
}


.skill--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.skill--box:hover  {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}


.skill--box > img {
    height: 50px;
    pointer-events: none;
}


.skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}


.marquee {
    padding: 3rem 0;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .skills {
        padding: 2rem 1rem 1rem 1rem;
    }
    .skillsContainer {
        padding: 1.5rem;
        margin-top: 1.5rem;
    }
}


@media screen and (max-width: 992px) {
    .skills {
        padding: 1rem;
        min-height: 100%;
    }
    .skillsContainer {
        padding: 1rem;
        margin: 1rem 0;
    }
    .skillsHeader h2 {
        font-size: 3.2rem;
    }
    .skill--box {
        width: 150px;
        height: 150px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 45px;
    }


    .skill--box h3 {
        font-size: 20px;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 800px) {
    .skills {
        padding: 0.5rem;
    }
    .skillsContainer {
        padding: 0.5rem;
    }
    .skillsHeader h2 {
        font-size: 3rem;
    }

    .skill--scroll {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skillsHeader h2 {
        font-size: 2.5rem;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.education {
    /* min-height: 100vh; */
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: auto;
    width: 100%;
    padding-top: 10px;
    /* top: -20px; */
    /* color: hsla(117, 56%, 49%, 0.702); */
}

.education-description {
    width: 100%;
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
}

.education-description > h1{
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 0.7rem;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.education-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width:  1000px;
    /* min-width: 400px; */
    margin: auto;
    /* height: 200px; */
    flex: auto;
    padding: 1.0rem;
    border-radius: 20px;
    margin-bottom: 1.0rem;
    transition: background-color 200ms ease-in-out;
}

.education-image-container {
    display: flex;
    flex: 30% 1; 
}

.education-details-container {
    display: flex;
    flex: 70% 1;
}


.educard-img {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 120px;
    height: 120px;
}

.educard-img > img {
    width: 50%;
    justify-content: center;
    /* margin: auto; */
    height: 50%;
    top: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
}

.education-details{  
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    /* margin-bottom: 0.5rem; */
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }

    .education-description {
        flex: 1 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-image-container {
        display: inline-block;
        /* display: flex; */
        width: 100%;
        height: 30%;
        justify-content: center;
        flex: 3 1;
    }
    
    .education-details-container {
        /* flex: 1; */
        display: inline-block;
        /* display: flex; */
    
        top: 50%;
        -webkit-transform: translateY(20%);
                transform: translateY(20%);
        width: 100%;
        height: 70%;
        text-align: center;
        line-height: 2em;
    }

    /* .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    } */
    .education-card {
        /* padding: 1rem;
        margin-bottom: 1.5rem; */
        width: 95%;
        display: flex;
        height: 360px;
        flex-direction: column;
        justify-content: center;
    }
    .educard-img {
        border-radius: 50%;
        margin-bottom: 80px;

        width: 80px;
        height: 80px;
    }
    
    .educard-img > img {
        width: 50%;
        height: 50%;
    }
    /* .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    */
    .education-details > h6 {
        opacity: 0.8;
    }
    .education-details > h2 {
        font-weight: lighter;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.experience{
    /* min-height: 100vh; */
}

.experience-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    /* flex: 0.65; */
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-right: 2rem; */
}

.experience-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    width: 1000px;
    /* height: 140px; */
    padding: 1.5rem;
    border-radius: 20px;
    margin: auto;
    /* margin-bottom: 1.5rem; */
    transition: background-color 200ms ease-in-out;
}

.expcard-img-container {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
} 

.expcard-details-container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.expcard-img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-shrink: 0; */
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

/* .experience-details {
    margin-left: 0.6rem;
} */

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}






@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .experience {
        min-height: 100%;
    }
    .experience-image {
        display: none;
    }
    .experience-description {
        flex: 1 1;
        margin: auto;
    }    
    .experience-description > h1{
        font-size: 3.2rem;
    }
    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .experience-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .experience-description {
        padding: 1rem;
    }
    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .expcard-img > img {
        width: 30px;
    }
    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .experience-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.contacts {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}


.contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 280px;
    pointer-events: none;
}

.contacts--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2rem 6rem 3rem; */
    width: 60%;
    height: 100%;
    /* margin-top: 2rem; */
}

.contacts--container > h1 {
    font-family: var(--primaryFont);
    font-size: 3.3rem;
    margin-bottom: 2.5rem;

}

.contacts-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.contacts-form {
    display: flex;
    align-items: center;
    /* flex: 0.4; */
    width: 100%;
}

.contacts-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

.input-container {
    width: 100%;
}


.form-input {
    height: 50px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%; 
    resize: none;
}



.form-message {
    height: 150px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%;
    resize: none;
}



.submit-btn button {
    border: none;
    outline: none;
    width: 140px;
    height: 50px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.submit-btn button p {
    font-size: 16px;
    font-family: var(--primaryFont);
}

.submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.send-icon {
    font-size: 25px;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(5px,-3px) rotate(-30deg);
            transform: translate(5px,-3px) rotate(-30deg);
}

.success-icon {
    font-size: 28px;
    transition: all 0.3s 0.8s ease-in-out;
}

.contacts-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* justify-content: space-evenly; */
    /* flex: 0.6; */
    box-sizing: border-box;
    /* padding-left: 7rem; */
    margin-bottom: 2.4rem;
    /* margin-top: 1.2rem; */
}



.personal-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2.5rem;
}

.personal-details p {
    margin-left: 1.5rem;
    font-size: 14px;
    line-height: 110%;
    font-weight: 500;
    font-family: var(--primaryFont);
    width: 45%;
    word-break: break-word;
}

.socialmedia-icons {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 4rem;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .contacts--img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 240px;
    }
    .contacts--container {
        padding: 2rem 4rem;
        width: 90%;
        margin-top: 1rem;
    }
}


@media screen and (max-width: 992px) {
    .contacts--img {
        display: none;
    }
    .contacts--container {
        width: 100%;
        margin-top: 1rem;
    }
    .contacts--container > h1 {
        font-size: 3rem;
        margin: 0 0 1.2rem 0;
    }
    .contacts-details {
        /* padding-left: 4rem; */
    }
    .personal-details p {
        margin-left: 1rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .contacts--container h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .contacts--container {
        padding: 2rem;
        margin-top: 0rem;
        align-items: center;
    }
    .contacts-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 80%;
    }
    
    .form-input {
        margin-bottom: 0.45rem;
    }
    .contacts-form form {
        align-items: flex-end;
    }
    .contacts-details {
        padding-left: 0rem;
        padding: 1rem;
        align-items: center;
        margin-top: 2rem;
    }
    .socialmedia-icons {
        width: 100%;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        margin-top: 2rem;
    }
    .personal-details {
        margin-bottom: 1.8rem;
    }
    .personal-details p {
        margin-left: 1.2rem;
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 100%;
    }
    .contacts--container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
        align-items: center;
        margin-top: 2rem;
    }
    .submit-btn{align-self: center;}
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
    }
}





@-webkit-keyframes spin{
    0%{
        opacity: 1;
        -webkit-transform: translate(5px,-3px) rotate(0deg);
                transform: translate(5px,-3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100%{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }





@keyframes spin{
    0%{
        opacity: 1;
        -webkit-transform: translate(5px,-3px) rotate(0deg);
                transform: translate(5px,-3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100%{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  @-webkit-keyframes fly{
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        20%{
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);    
        }
        70%{
          -webkit-transform: translateX(60px);
                  transform: translateX(60px);
        }
        100%{
            -webkit-transform: translateX(80px);
                    transform: translateX(80px);
        }
  }

  @keyframes fly{
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        20%{
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);    
        }
        70%{
          -webkit-transform: translateX(60px);
                  transform: translateX(60px);
        }
        100%{
            -webkit-transform: translateX(80px);
                    transform: translateX(80px);
        }
  }
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* min-height: 100vh; */
    overflow-x: hidden;
    padding: 2rem;
}


.projects--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.projects--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.projects--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    width: 100%;
}

.projects--viewAll {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
}

.projects--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {

    .projects--header {
        margin: auto;
        text-align: center;
    }
    .projects--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projects--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .projects--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .projects--viewAll {
        position: relative;
        margin-top: 4rem;
    }
    .projects--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleProject {
    /* box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2); */
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 60%;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
    -webkit-transform: translateX(-140px) scale(1.1) !important;
            transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 160px;
    left: 0;
    top: 0;
    -webkit-transform: translate(-110%,40%);
            transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: -webkit-transform 0.9s;
    transition: transform 0.9s;
    transition: transform 0.9s, -webkit-transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    -webkit-transform: translate(-2%,40%);
            transform: translate(-2%,40%);
}
  
.singleProject .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
    padding: 0.825rem;
}
  
.singleProject:hover .project--lang{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.project--lang > span {
    font-family: var(--primaryFont);
    font-weight: 500;
    word-break: break-word; 
    line-height: 100%;
}
.achievement {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding: 3rem; */
}

.achievement-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly;
    flex: 0.4; */
    width: 100%;
}

.achievement-body h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
}

.achievement-body h4 {
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.achievement-cards {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    /* margin-left: 5%; */
    width:  65%;
    /* flex: 0.6; */
}

.achievement-card {
    display: flex;
    flex-direction: colunmn; 
    /* align-items: flex-start; */
    /* justify-content: space-between; */
    min-height: 170px;
    padding: 1.5rem;
    border-radius: 20px; 
    margin-bottom: 1.5rem;
    /* margin-left: 1.5rem;  */
    transition: background-color 200ms ease-in-out;
    width: 100%;
    /* position: relative; */
}

.achievecard-content {
    /* display: flex;
    flex-direction: column; */
    /* align-items: flex-start;
    justify-content: space-around; */
    /* width: 65%; */
   
}

.achievecard-details2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    position: absolute;
    bottom: 7px;
    left: 25px;
}

.achievecard-details2 h5 {
   width: 50%;
}

.achievecard-field {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.achievecard-field svg {
    margin-right: 6px;
}

.achievecard-details1 h2 {
    font-family: var(--primaryFont);
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 110%;
    margin-bottom: 6px;
}

.achievecard-details1 p {
    font-size: 1.10rem;
    font-family: Roboto;
    font-weight: 400;
}

.achievecard-details-container {
    display: flex;
    flex: 70% 1;
}

.achievecard-imgcontainer {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
 

.achievecard-imgcontainer > img {
    width: 150px;
    height: 130px;
    border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .achievement-card {
        justify-content: flex-start;
    }

    .achievement-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        flex: 0.3 1;
        width: 90%;
    }
    .achievement-body h1 {
        font-family: var(--primaryFont);
        font-size: 3.1rem;
    }
    
    .achievement-cards {
        padding: 1rem;
        margin-left: 1%;
        width: 100%;
        flex: 0.7 1;
    }
    .achievecard-imgcontainer {
       margin-left: 1rem;
    }

}

@media screen and (max-width: 992px) {
    .achievement{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2rem;
    }


    .achievement-body {
        width: 100%;
    }

    .achievement-body h1 {
        font-size: 2.5rem;
    }

    .achievement-cards {
        /* margin-left: 0%; */
        padding: 1rem 0;
        width: 80%;
        margin-top: 1rem;
    }

    .achievement-card {
        /* margin-left: 0px; */
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .achievecard-content {
        width: 70%;
    }

    .achievecard-details1 {
        margin-bottom: 20px;
    }

    .achievecard-details1 h2 {
        font-size: 1.15rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }

    .achievecard-details2 {
        width: 85%;
        position: absolute;
        bottom: 7px;
        left: 25px;
    }

    .achievecard-imgcontainer {
        width: 30%;
        display: flex;
       justify-content: center;
    }

    .achievecard-imgcontainer > img {
        width: 80px;
        height: 70px;
    }
    
}

@media screen and (max-width: 600px) {
    .achievement{
        padding: 1rem;
    }
    .achievement-cards {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    .achievement-body h4 {
        font-size: 1.5rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }
    .achievecard-imgcontainer > img {
        width: 70px;
        height: 60px;
    }

}


.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
.projectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}

.projectPage-header {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.projectPage-header > h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.projectPage-container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.projectPage-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    margin-top: 5rem;
    width: 100%;
}

.project-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem; 
}

@media (min-width: 992px) and (max-width: 1380px) {

}

@media screen and (max-width: 992px) {
    .projectPage-header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .project-grid {
        grid-gap: 3rem;
        gap: 3rem;
    }
}

@media screen and (max-width: 702px) {
    .project-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .projectPage-header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .projectPage-header {
        height: 20vh;
    }
    .projectPage-header h1 {
        font-size: 2.5rem;
    }
}
:root {
    /* --primaryFont: 'Poppins', sans-serif; */
    --primaryFont: 'Montserrat', sans-serif;
    /* --primaryFont: 'Raleway', sans-serif; */
}


