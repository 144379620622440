.education {
    /* min-height: 100vh; */
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: auto;
    width: 100%;
    padding-top: 10px;
    /* top: -20px; */
    /* color: hsla(117, 56%, 49%, 0.702); */
}

.education-description {
    width: 100%;
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
}

.education-description > h1{
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 0.7rem;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.education-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width:  1000px;
    /* min-width: 400px; */
    margin: auto;
    /* height: 200px; */
    flex: auto;
    padding: 1.0rem;
    border-radius: 20px;
    margin-bottom: 1.0rem;
    transition: background-color 200ms ease-in-out;
}

.education-image-container {
    display: flex;
    flex: 30%; 
}

.education-details-container {
    display: flex;
    flex: 70%;
}


.educard-img {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 120px;
    height: 120px;
}

.educard-img > img {
    width: 50%;
    justify-content: center;
    /* margin: auto; */
    height: 50%;
    top: 50%;
    transform: translateY(50%);
}

.education-details{  
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    /* margin-bottom: 0.5rem; */
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }

    .education-description {
        flex: 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-image-container {
        display: inline-block;
        /* display: flex; */
        width: 100%;
        height: 30%;
        justify-content: center;
        flex: 3;
    }
    
    .education-details-container {
        /* flex: 1; */
        display: inline-block;
        /* display: flex; */
    
        top: 50%;
        transform: translateY(20%);
        width: 100%;
        height: 70%;
        text-align: center;
        line-height: 2em;
    }

    /* .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    } */
    .education-card {
        /* padding: 1rem;
        margin-bottom: 1.5rem; */
        width: 95%;
        display: flex;
        height: 360px;
        flex-direction: column;
        justify-content: center;
    }
    .educard-img {
        border-radius: 50%;
        margin-bottom: 80px;

        width: 80px;
        height: 80px;
    }
    
    .educard-img > img {
        width: 50%;
        height: 50%;
    }
    /* .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    */
    .education-details > h6 {
        opacity: 0.8;
    }
    .education-details > h2 {
        font-weight: lighter;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}